<template>
  <div class="uk-margin-top">
    <div class="uk-overflow-auto">
      <table class="uk-table uk-table-striped uk-table-hover uk-table-small uk-table-divider">
        <thead class="thead-table-paranje">
          <tr>
            <th class="uk-width-small">
              <div class="uk-flex uk-flex-middle">
                <span class="uk-text-bold uk-width-expand">Stock Transfer</span>
                <div class="uk-float-right uk-flex-column uk-width-auto uk-margin-small-bottom">
                  <div v-if="imgSrc == 'codeDown'">
                    <div class="sort-icon">
                      <img
                        :src="`${images}/icon/Vectorupper.svg`"
                        alt=""
                        class="img-click"
                        @click="sortSwitch('codeUp'), changeSort('order_by', 'code', 'sort_by', 'ASC')"
                      >
                    </div>
                    <div class="sort-icon-down">
                      <img
                        :src="`${images}/icon/VectordownInnactive.svg`"
                        alt=""
                        class="img-click"
                      >
                    </div>
                  </div>
                  <div v-else-if="imgSrc == 'codeUp'">
                    <div class="sort-icon">
                      <img
                        :src="`${images}/icon/VectorupperInnactive.svg`"
                        alt=""
                        class="img-click"
                      >
                    </div>
                    <div class="sort-icon-down">
                      <img
                        :src="`${images}/icon/Vectordown.svg`"
                        alt=""
                        class="img-click"
                        @click="sortSwitch('codeDown'), changeSort('order_by', 'code', 'sort_by', 'DESC')"
                      >
                    </div>
                  </div>
                  <div v-else>
                    <div class="uk-margin-small-bottom">
                      <div class="sort-icon">
                        <img
                          :src="`${images}/icon/Vectorupper.svg`"
                          alt=""
                          class="img-click"
                          @click="sortSwitch('codeUp'), changeSort('order_by', 'code', 'sort_by', 'ASC')"
                        >
                      </div>
                      <div class="sort-icon-down">
                        <img
                          :src="`${images}/icon/Vectordown.svg`"
                          alt=""
                          class="img-click"
                          @click="sortSwitch('codeDown'), changeSort('order_by', 'code', 'sort_by', 'DESC')"
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </th>
            <th class="uk-width-small">
              <div class="uk-flex uk-flex-middle uk-padding-remove-bottom">
                <span class="uk-text-bold uk-margin-small">Kode Siklus</span>
              </div>
            </th>
            <th class="uk-width-small">
              <div class="uk-flex uk-flex-middle uk-padding-remove-bottom">
                <span class="uk-text-bold uk-margin-small">Nama Mitra</span>
              </div>
            </th>
            <th class="uk-width-small">
              <div class="uk-flex uk-flex-middle uk-padding-remove-bottom">
                <span class="uk-text-bold uk-margin-small">Nama Kandang</span>
              </div>
            </th>
            <th class="uk-width-small">
              <div class="uk-flex uk-flex-middle uk-padding-remove-bottom">
                <span class="uk-text-bold uk-margin-small">Siklus ke</span>
              </div>
            </th>
            <th class="uk-width-small">
              <div class="uk-flex uk-flex-middle uk-padding-remove-bottom">
                <span class="uk-text-bold uk-margin-small">Status</span>
              </div>
            </th>
            <th class="uk-width-small">
              <div class="uk-flex uk-flex-middle">
                <span class="uk-text-bold uk-width-expand uk-margin-small-right">Terakhir Diubah</span>
                <div class="uk-float-right uk-flex-column uk-width-auto">
                  <div class="uk-float-right uk-flex-column uk-width-auto uk-margin-small-bottom">
                    <div v-if="imgSrc == 'sortCreateDown'">
                      <div class="sort-icon">
                        <img
                          v-lazy="`${images}/icon/Vectorupper.svg`"
                          alt=""
                          class="img-click"
                          @click="sortSwitch('sortCreateUp'), changeSort('order_by', 'updated_at', 'sort_by', 'ASC')"
                        >
                      </div>
                      <div class="sort-icon-down">
                        <img
                          v-lazy="`${images}/icon/VectordownInnactive.svg`"
                          alt=""
                          class="img-click"
                        >
                      </div>
                    </div>
                    <div v-else-if="imgSrc == 'sortCreateUp'">
                      <div class="sort-icon">
                        <img
                          v-lazy="`${images}/icon/VectorupperInnactive.svg`"
                          alt=""
                          class="img-click"
                        >
                      </div>
                      <div class="sort-icon-down">
                        <img
                          v-lazy="`${images}/icon/Vectordown.svg`"
                          alt=""
                          class="img-click"
                          @click="sortSwitch('sortCreateDown'), changeSort('order_by', 'updated_at', 'sort_by', 'DESC')"
                        >
                      </div>
                    </div>
                    <div v-else>
                      <div class="sort-icon">
                        <img
                          v-lazy="`${images}/icon/Vectorupper.svg`"
                          alt=""
                          class="img-click"
                          @click="sortSwitch('sortCreateUp'), changeSort('order_by', 'updated_at', 'sort_by', 'ASC')"
                        >
                      </div>
                      <div class="sort-icon-down">
                        <img
                          v-lazy="`${images}/icon/Vectordown.svg`"
                          alt=""
                          class="img-click"
                          @click="sortSwitch('sortCreateDown'), changeSort('order_by', 'updated_at', 'sort_by', 'DESC')"
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </th>
            <th class="uk-width-small">
              <div class="uk-flex uk-flex-middle uk-padding-remove-bottom">
                <span class="uk-text-bold uk-margin-small">Aksi</span>
              </div>
            </th>
          </tr>
        </thead>
        <template v-if="!is_loading">
          <tbody v-if="sapronak.meta.total_count > 0">
            <tr
              v-for="(doc, i) in sapronak.data"
              :key="i"
            >
              <td>{{ doc.code || "-" }}</td>
              <td>{{ doc.log_cycle_code || "-" }}</td>
              <td>{{ doc.farm_name || "-" }}</td>
              <td>{{ doc.cage_name || "-" }}</td>
              <td>{{ doc.cycle_count }}</td>
              <td>
                <LabelStatus :status="doc.status" />
              </td>
              <td>{{ formatDate(doc.updated_at) || "-" }}</td>
              <td>
                <img
                  v-if="isCanAccessUser(`edit`, `Sapronak`)"
                  v-lazy="`${images}/icon/eye.svg`"
                  alt=""
                  class="img-click"
                  @click="showDetail(doc.id)"
                >
              </td>
            </tr>
          </tbody>
          <empty-table
            v-else
            :colspan="10"
          />
        </template>
        <template v-else>
          <loading-table :colspan="10" />
        </template>
      </table>
    </div>
    <pagination
      :total-data="sapronak.meta.total_count"
      :change-limit="changeLimit"
      :change-page="changePage"
      :current-page="meta.page"
      :current-limit="meta.limit"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import Pagination from "@/components/globals/Pagination"
import EmptyTable from "@/components/globals/table/EmptyTable"
import LoadingTable from "@/components/globals/table/LoadingTable"
import { STATUS } from "@/utils/constant"
import { dateUtcParanjeString } from "@/utils/formater"
import { PREFIX_IMAGE } from "@/utils/constant"
import { isCanAccess } from "@/utils/auth"
import LabelStatus from "@/components/globals/LabelStatus"

export default {
  components: {
    EmptyTable,
    LoadingTable,
    Pagination,
    LabelStatus
  },
  data() {
    return {
      is_loading: true,
      status: STATUS,
      images: PREFIX_IMAGE,
      imgSrc: "codeDown"
    }
  },
  computed: {
    ...mapGetters({
      sapronak: "sapronak/sapronak",
      meta: "sapronak/meta"
    })
  },
  watch: {
    async meta() {
      this.is_loading = true
      await this.getSapronak({ ...this.meta })
      this.is_loading = false
    }
  },
  async mounted() {
    this.setMeta({
      ...this.meta,
      order_by: "code",
      sort_by: "DESC"
    })
    await this.getSapronak({ ...this.meta })
    this.is_loading = false
  },
  methods: {
    formatDate(date) {
      return dateUtcParanjeString(date)
    },
    isCanAccessUser(type, menu) {
      return isCanAccess(type, menu)
    },
    ...mapActions({
      getSapronak: "sapronak/getSapronak"
    }),
    ...mapMutations({
      setMeta: "sapronak/SET_META"
    }),
    changeLimit(e) {
      this.setMeta({
        ...this.meta,
        page: 1,
        limit: e.target.value
      })
    },
    changePage(value) {
      this.setMeta({
        ...this.meta,
        page: value
      })
    },
    showDetail(id) {
      this.$router.push(`/admin/sapronak/detail-sapronak/${id}`)
    },
    changeSort(field, fieldValue, sortDirection, sortDirectionValue) {
      this.setMeta({
        ...this.meta,
        [field]: fieldValue,
        [sortDirection]: sortDirectionValue
      })
    },
    sortSwitch(a) {
      this.imgSrc = a
    }
  }
}
</script>
