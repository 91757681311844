<template>
  <div>
    <div class="uk-card uk-card-default uk-padding-small kandang-card">
      <FilterRetur />
      <TableRetur />
    </div>
  </div>
</template>
<script>
import FilterRetur from './filterRetur'
import TableRetur from './tableRetur'

export default {
  components: {
    FilterRetur,
    TableRetur
  }
}
</script>
