<template>
  <div>
    <div class="uk-card uk-card-default uk-padding-small kandang-card">
      <FilterSapronak />
      <TableSapronak />
    </div>
  </div>
</template>
<script>
import FilterSapronak from './filterSapronak'
import TableSapronak from './tableSapronak'

export default {
  components: {
    FilterSapronak,
    TableSapronak
  }
}
</script>
