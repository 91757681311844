<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div
      class="uk-child-width-expand@s uk-grid-small"
      uk-grid
    >
      <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l font-header">
        {{ headerTitle }}
      </div>
      <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l uk-text-right">
        <button
          v-if="!is_loading"
          class="uk-button uk-button-primary uk-margin-left"
          :disabled="is_loading"
          @click="handleSync"
        >
          <img
            v-lazy="`${images}/icon/sync.svg`"
            alt=""
            class="uk-margin-small-right img-click"
          >
          Sync Data
        </button>
        <button
          v-else
          style="cursor:unset; min-width:167.47px;"
          class="uk-button uk-button-primary uk-margin-left"
        >
          <div uk-spinner />
        </button>
      </div>
    </div>

    <container
      :click-sapronak="clickSapronak"
      :click-retur="clickRetur"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import getHeaderTitle from '@/utils/header-title'
import Container from './container'
import { dateString } from '@/utils/formater'
import { PREFIX_IMAGE } from '@/utils/constant'
import {
  notificationSuccess
} from '@/utils/notification'

export default {
  components: {
    Container
  },
  data() {
    return {
      images: PREFIX_IMAGE,
      is_loading: false,
      transaction_type: 'delivery'
    }
  },
  computed: {
    headerTitle() {
      return getHeaderTitle(this.$route.fullPath)
    },
    ...mapGetters({
      meta: 'sapronak/meta',
      metaRetur: 'sapronak/metaRetur',
      syncDataSapronak: 'sapronak/sync_data'
    })
  },
  methods: {
    ...mapActions({
      syncSapronak: 'sapronak/syncSapronak'
    }),
    ...mapMutations({
      setMeta: 'sapronak/SET_META',
      setMetaRetur: 'sapronak/SET_META_RETUR'
    }),
    getDatetime(date) {
      return dateString(date)
    },
    clickSapronak () {
      this.transaction_type = 'delivery'
    },
    clickRetur () {
      this.transaction_type = 'return'
    },
    async handleSync () {
      this.is_loading = true
      await this.syncSapronak({ transaction_type: this.transaction_type })
      if (this.syncDataSapronak.message == 'OK') {
        if (this.transaction_type == 'delivery') {
          notificationSuccess(`Sync Sapronak Berhasil`)
          await this.setMeta({
            ...this.meta
          })
        } else {
          notificationSuccess(`Sync Retur Barang Berhasil`)
          await this.setMetaRetur({
            ...this.metaRetur
          })
        }
      }
      this.is_loading = false
    }
  }
}
</script>
