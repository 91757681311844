<template>
  <div class="uk-margin-top">
    <ul
      class="uk-subnav uk-subnav-pill"
      uk-switcher
    >
      <li>
        <a
          href="#"
          @click="clickSapronak"
        >Sapronak</a>
      </li>
      <li>
        <a
          href="#"
          class="tabs-left-kandang"
          @click="clickRetur"
        >Retur Barang</a>
      </li>
    </ul>
    <ul class="uk-switcher uk-margin">
      <li>
        <Sapronak />
      </li>
      <li>
        <ReturBarang />
      </li>
    </ul>
  </div>
</template>
<script>
import Sapronak from './component/ContainerSapronak'
import ReturBarang from './component/ContainerRetur'

export default {
  components: {
    Sapronak,
    ReturBarang
  },
  props: {
    clickSapronak: {
      required: true,
      type: Function
    },
    clickRetur: {
      required: true,
      type: Function
    }
  }
}
</script>

<style scoped>
.uk-subnav-pill > .uk-active > a {
  background-color: #ffffff;
  color: #005331 !important;
  border-radius: 10px;
  padding: 15px;
}

.uk-subnav {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  padding: 0;
  margin-bottom: -10px !important;
}

.uk-subnav-pill > * > :first-child {
  padding: 15px;
  background: #E0EBE6;
  color: #85AD9C;
  border-radius: 10px;
}

.uk-margin {
  margin-top: 0px !important;
}
</style>
